import React from "react";

function PageNoteFound() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1 style={{ color: "#000", fontSize: "2rem" }}>Page Not Found!!</h1>
      </div>
    </>
  );
}

export default PageNoteFound;
