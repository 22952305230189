import React, { useState, useEffect } from "react";
import IsLoadingHOC from "../Common/IsLoadingHOC";
import { authAxios } from "../config/config";
import { toast } from "react-toastify";
import { getFormatedDate, replaceHyphenCapitolize } from "../Helper";
import IsLoggedinHOC from "../Common/IsLoggedInHOC";

interface MyComponentProps {
  setLoading: (isComponentLoading: boolean) => void;
  isLoading: boolean;
  subscriptionId: string;
  quotationId: string;
  quotationType: string;
  setActiveSidebar: (activeSidebarMenu: string) => void;
}

function Units(props: MyComponentProps) {
  const { isLoading, setLoading, subscriptionId, quotationId, quotationType, setActiveSidebar } = props;
  const [units, setUnits] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemPerPage] = useState<number>(10);
  const [requested, setRequested] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  const getSubscriptionDetails = async () => {
    const payload = {
      quotationId,
      quotationType,
      page: currentPage,
      limit: itemsPerPage,
    };
    setLoading(true);
    await authAxios()
      .post(`/inventory/find-by-qrcodes-quote-type-id`, payload)
      .then(
        (response: any) => {
          setLoading(false);
          if (response.data.status === 1) {
            const res = response.data.data;

            setUnits(res.inventories);
            setTotalCount(res?.pagination.totalItems);

            const totalPending = res.quotation.numUnits - res.inventories.length

            setRequested(res.quotation.numUnits)

          } else {
            toast.error(response.data.message);
            setUnits([]);
            setTotalCount(0);
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data.message);
          setUnits([]);
          setTotalCount(0);
        }
      )
      .catch((error) => {
        console.log("errorrrr", error);
      });
  };

  function getSVGContentFromDataURL(dataUrl: string) {
    const prefix = "data:image/svg+xml;utf8,";

    const svgContent = dataUrl.startsWith(prefix)
        ? decodeURIComponent(dataUrl.slice(prefix.length))
        : null;

    if (svgContent) {
      const modifiedSVGContent = svgContent.replace(/<text.*?<\/text>/g, '')
                                           .replace('viewBox="0 0 41 50"', 'viewBox="0 0 40 40"');

        return modifiedSVGContent;
    }

    return null;
  };


  return (
    <>
      <div className="quotations--content subscription--content">
        <div style={{display: 'flex', justifyContent: "space-between", alignItems: 'center'}} className="dashboard--content--title">
            <div>
              <h2>
                <span className="back--btn--wrapper">
                  <span>
                    <a
                      onClick={() => setActiveSidebar("MY_SUBSCRIPTIONS")}
                      className="back--btn"
                    >
                      <img
                        src={require("../asstes/image/arrow--left.png")}
                        alt=""
                      />
                    </a>
                  </span>{" "}
                  <span>Units for {subscriptionId.slice(-8)?.toUpperCase()}</span>
                </span>
              </h2>
            </div>

            <div style={{display: 'flex', justifyContent: 'end', margin: '10px'}}>
              <span style={{display: 'flex', gap: '20px', fontWeight: '600'}}>
                <span className="dashboard--content--title">{units.length} Assigned</span>
                <span className="dashboard--content--title">{requested - units.length > 0 ? requested - units.length : 0 } Pending</span>
                <span className="dashboard--content--title">{requested} Total Requested</span>
              </span>
            </div>
        </div>
        <div className="table--wrapper">
            <table>
            <thead>
                <tr>
                <th>ID</th>
                <th>Product Name</th>
                <th>Category</th>
                <th>Type</th>
                <th>Gender</th>
                <th>Requests</th>
                <th>QR Code</th>
                </tr>
            </thead>
            <tbody>
                {units &&
                 units.length > 0 &&
                 units.map((item: any) => (
                    <tr key={item._id}>
                      <td>{item.qrId}</td>
                      <td>{item?.productName}</td>
                      <td>{item.category}</td>
                      <td>{item.type}</td>
                      <td>{item.gender}</td>
                      <td style={{textAlign: 'center'}}>{ item.serviceRequestCount ? item.serviceRequestCount : 0  }</td>
                      <td>
                        <div style={{width: '100%', height: '100%'}} dangerouslySetInnerHTML={{ __html: getSVGContentFromDataURL(item?.qrCode) || '' }} />
                      </td>
                    </tr>
                ))}
            </tbody>
            </table>
        </div>
     </div>
    </>
  );
}
export default IsLoadingHOC(IsLoggedinHOC(Units));